
















































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

import ReportCoverPageComponent from "../components/ReportCoverPageComponent.vue";
import ReportNoticeComponent from "../components/ReportNoticeComponent.vue";
import SpinnerComponent from "../components/SpinnerComponent.vue";
import SocialProcurementComponent from "../components/SocialProcurementComponent.vue";
import SocialProcurementReportComponent from "../components/SocialProcurementReportComponent.vue";
import IndigenousBenefitsReportComponent from "../components/IndigenousBenefitsReportComponent.vue";
import WorkforceDiversityComponent from "../components/WorkforceDiversityComponent.vue";
import DiversityBreakdownComponent from "../components/DiversityBreakdownComponent.vue";
import IndigenousProcurementProjectComponent from "../components/IndigenousProcurementProjectComponent.vue";
import IndigenousProcurementSummaryComponent from "../components/IndigenousProcurementSummaryComponent.vue";
import IndigenousProcurementCommunityComponent from "../components/IndigenousProcurementCommunityComponent.vue";
import IndigenousProcurementVendorComponent from "../components/IndigenousProcurementVendorComponent.vue";
import SummaryTargetsComponent from "../components/SummaryTargetsComponent.vue";
import CCABCommunityRelationsComponent from "../components/CCABCommunityRelationsComponent.vue";
import CCABBusinessDevelopmentComponent from "../components/CCABBusinessDevelopmentComponent.vue";
import CCABReportNotice from "../components/CCABReportNotice.vue";
import CCABDiversityComponent from "../components/CCABDiversityComponent.vue";
import EIRDistributionComponent from "../components/EIRDistributionComponent.vue";
import DEReportNotice from "../components/DEReportNotice.vue";
import ExpenseLocationSummaryReportComponent from "../components/ExpenseLocationSummaryReportComponent.vue";
import ExpenseLocationProjectReportComponent from "../components/ExpenseLocationProjectReportComponent.vue";
import ExpenseLocationOverheadReportComponent from "../components/ExpenseLocationOverheadReportComponent.vue";
import PehtaSummaryReportComponent from "../components/PehtaSummaryReportComponent.vue";
import PehtaBreakdownReportComponent from "../components/PehtaBreakdownReportComponent.vue";
import PehtaCorporateBreakdownReportComponent from "../components/PehtaCorporateBreakdownReportComponent.vue";
import PehtaCorporateSummaryReportComponent from "../components/PehtaCorporateSummaryReportComponent.vue";
import UNSDGBreakdownReportComponent from "../components/UNSDGBreakdownReportComponent.vue";
import CompactEIRDistributionComponent from "../components/CompactEIRDistributionComponent.vue";
import InclusionDevelopmentComponent from "../components/InclusionDevelopmentComponent.vue";

import { ReportData } from "../store/models";
import { convertToPercent } from "../lib/currency";
import * as charts from "../lib/charts";
import * as _ from "lodash";

// High Charts
import { Chart } from "highcharts-vue";
import Highcharts, { chart } from "highcharts";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import loadExporting from "highcharts/modules/exporting";
import loadExportData from "highcharts/modules/export-data";
import loadDrilldown from "highcharts/modules/drilldown";
import loadDataTableToggle from "../lib/highcharts-datatable.js";
import loadTreemap from "highcharts/modules/treemap";
import * as testData from "../lib/test-data";
import { ReportType } from "../components/ReportHelper";
import ReportHelper from "../components/ReportHelper";
import { base64Decode } from "@/lib/reports";
import { isFeatureEnabled } from "@/lib/shared";
import * as features from "@/lib/feature-constants";

loadDrilldown(Highcharts);
loadExporting(Highcharts);
loadExportData(Highcharts);
HighchartsNoData(Highcharts);
loadDataTableToggle(Highcharts);
loadTreemap(Highcharts);

@Component({
  components: {
    highcharts: Chart,
    ReportCoverPageComponent,
    ReportNoticeComponent,
    CCABReportNotice,
    SocialProcurementComponent,
    SocialProcurementReportComponent,
    IndigenousBenefitsReportComponent,
    WorkforceDiversityComponent,
    DiversityBreakdownComponent,
    IndigenousProcurementProjectComponent,
    IndigenousProcurementSummaryComponent,
    IndigenousProcurementCommunityComponent,
    IndigenousProcurementVendorComponent,
    CCABCommunityRelationsComponent,
    CCABBusinessDevelopmentComponent,
    CCABDiversityComponent,
    EIRDistributionComponent,
    SummaryTargetsComponent,
    SpinnerComponent,
    DEReportNotice,
    ExpenseLocationSummaryReportComponent,
    ExpenseLocationProjectReportComponent,
    ExpenseLocationOverheadReportComponent,
    PehtaSummaryReportComponent,
    PehtaBreakdownReportComponent,
    PehtaCorporateBreakdownReportComponent,
    PehtaCorporateSummaryReportComponent,
    UNSDGBreakdownReportComponent,
    CompactEIRDistributionComponent,
    InclusionDevelopmentComponent,
  },
})
export default class SocialImpactReportExternal extends Vue {
  protected baseRoute: string = "/reports";
  private resizeCharts: number = 0;
  private updateArgs: any = charts.getUpdateArgs();
  private convertToPercent = convertToPercent;
  private reportData: ReportData | null = null;
  private reportType: string | null = null;
  private ReportType = ReportType;
  private isLoadedTimeout: boolean = false;
  private isFeatureEnabled = isFeatureEnabled;
  private features = features;

  private reportHelper: ReportHelper = new ReportHelper(ReportType.Unknown);

  private testMode: boolean = false; // Change to true to test.

  private eirSections = [
    {
      field: "gender",
      title: "Gender Identity",
      levelCutoff: 5,
      locationCutoff: 5,
      occupationCutoff: 5,
      question: "Which gender identity best describes you?",
    },
    {
      field: "orientation",
      title: "Sexual Orientation",
      levelCutoff: 5,
      locationCutoff: 5,
      occupationCutoff: 5,
      question: "Which sexual orientation best describes you?",
    },
    {
      field: "ethnicity",
      title: "Ethnicity & Heritage",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question:
        "Please select any of the communities you identify with. The intent of this question is to understand the proportion on the workforce that is Indigenous or part of a visible minority, using categories consistent with Canada’s Employment Equity Act.",
    },
    {
      field: "indigenous",
      title: "Indigenous Identity",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question: "What goes here?",
    },
    {
      field: "disability",
      title: "Disabilities",
      levelCutoff: 5,
      locationCutoff: 5,
      occupationCutoff: 5,
      question: "If applicable, Please state any disabilities you wish to share.",
    },
    {
      field: "age",
      title: "Age",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question: "What year were you born?",
    },
    {
      field: "religion",
      title: "Faith & Religion",
      levelCutoff: 5,
      locationCutoff: 5,
      occupationCutoff: 5,
      question: "Please select the Faith & Religion you identify with:",
    },
    {
      field: "education",
      title: "Education",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question: "What is the highest degree or level of education you have completed? If currently enrolled, highest degree received.",
    },
    {
      field: "immigration",
      title: "Immigration Status",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question: "Have you - within the past 5 years - immigrated and been permitted to reside in Canada by immigration authorities?",
    },
    {
      field: "refugee",
      title: "Refugee Status",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question: "Have you fled your home country from persecution and been permitted to reside in Canada as a refugee by immigration authorities?",
    },
    {
      field: "veteran",
      title: "Veteran Status",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question:
        "Have you honourably served in the Canadian Armed Forces, the commonwealth, or its wartime allies, or as a Regular Member of the Royal Canadian Mounted Police, or as a Peace Officer in a Special Duty Area or on a Special Duty Operation, or who has served in the Merchant Navy or Ferry Command during wartime?",
    },
    {
      field: "barriers",
      title: "Barriers to Employment",
      levelCutoff: 5,
      locationCutoff: 5,
      occupationCutoff: 5,
      question: "If applicable, Please state any barrier to employment you've experienced.",
    },
  ];

  private getReportLoadedClass(): string {
    return "nested-content" + (this.isLoadedTimeout ? " pdf-shutter" : "");
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private updateData(val: any) {
    this.reportData = this.transformData(JSON.parse(base64Decode(val)));

    if (this.reportData) {
      this.$store.commit("session/SET_CURRENT_USER", this.reportData.userInfo);
    }

    // @ts-ignore
    document.body.style.zoom = "85%";
    this.resizeCharts++;
    this.sleep(5000).then(() => {
      this.isLoadedTimeout = true;
      return;
    });
  }

  private mounted() {
    this.reportType = this.$route.params.reportType;
    this.testMode = this.$route.query["test"] === "true";
    this.reportHelper.reportType = this.reportHelper.reportTypeStringToEnum(this.reportType);
    if (!this.testMode) {
      (window as any).updateData = this.updateData;
    } else {
      // Test mode - load the appropriate test data.
      // @ts-ignore
      document.body.style.zoom = "85%";
      switch (this.reportHelper.reportTypeStringToEnum(this.reportType)) {
        case ReportType.SocialImpactCorporate:
          this.reportData = this.transformData(testData.getCorporateSocialImpactTestData());
          break;
        case ReportType.SocialImpactProject:
          this.reportData = this.transformData(testData.getProjectSocialImpactTestData());
          break;
        case ReportType.TangibleNetBenefitsSummary:
          this.reportData = this.transformData(testData.getTangibleNetBenefitsSummaryTestData());
          break;
        case ReportType.SocialProcurementCorporate:
          this.reportData = this.transformData(testData.getCorporateSocialProcurementTestData());
          break;
        case ReportType.SocialProcurementProject:
          this.reportData = this.transformData(testData.getProjectSocialProcurementTestData());
          break;
        case ReportType.DiversityInEmploymentCorporate:
          this.reportData = this.transformData(testData.getCorporateEmploymentTestData());
          break;
        case ReportType.DiversityInEmploymentProject:
          this.reportData = this.transformData(testData.getProjectEmploymentTestData());
          break;
        case ReportType.SocialProcurementCommunityProject:
          this.reportData = this.transformData(testData.getSocialProcurementCommunityProjectTestData());
          break;
        case ReportType.SocialProcurementCommunitySummary:
          this.reportData = this.transformData(testData.getSocialProcurementCommunitySummaryTestData());
          break;
        case ReportType.SocialProcurementCommunity:
          this.reportData = this.transformData(testData.getSocialProcurementCommunityTestData());
          break;
        case ReportType.IndigenousImpactCommunity:
          this.reportData = this.transformData(testData.getIndigenousImpactCommunityTestData());
          break;
        default:
      }
    }
    // console.log(JSON.stringify(this.reportData));
    this.mount();
  }

  private mount() {
    // BUG: the highcharts data tables are not rendering unless a resize event is triggered. unsure whats causing this, the below fires an event to have them render
    if (this.testMode) {
      // Just resize once in test mode.
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    } else {
      // For some reason we must use setInterval here when rendering in RestPack - not sure why.
      setInterval(() => {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    }
  }

  private transformData(data: any): any {
    /*
    switch (this.reportHelper.reportTypeStringToEnum(this.reportType)) {
        case ReportType.ProjectEmail:
          // Transform the aggregate employment to a usable form.
          data.employment = convertAggregateToOccupationAggregate(data.orgAggregate);
          data.employmentTotals = convertAggregateToEmploymentTotals(data.orgAggregate);
          break;
        default:
      }
    */
    return data;
  }

  private isDataPresent(data: any): boolean {
    return data && JSON.stringify(data) !== "{}";
  }

  private isReportType(type: ReportType): boolean {
    return this.reportHelper.reportTypeEnumToString(type) === this.reportType;
  }
}

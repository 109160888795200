








































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class DEReportNotice extends Vue {
  @Prop() private reportData: any;

  protected mounted() {
    console.log(this.reportData);
  }

  get recordCount(): number {
    return this.reportData.eirTotalData.gender.totals.headcount[0].total;
  }

  get endpointRecordCount(): number {
    return this.reportData.eirTotalData.gender.totals.headcount[1].total;
  }

  get femaleDistribution(): string {
    const column = (this.reportData.eirTotalData.gender.totals.cross_analysis[0].columns as any[]).find((col) => col.column_value === "female");
    if (column) {
      return this.calculatePercentage((column.codes as string[]).length);
    }
    return "0";
  }

  get indigenousDistribution(): string {
    const column = (this.reportData.eirTotalData.ethnicity.totals.cross_analysis[0].columns as any[]).find((col) => col.column_value === "indigenous");
    if (column) {
      return this.calculatePercentage((column.codes as string[]).length);
    }
    return "0";
  }

  get minorityDistribution(): string {
    const codes = new Set<string>();

    const minorityCodes = (this.reportData.eirTotalData.ethnicity.totals.cross_analysis[0].columns as any[])
      .filter((col) => col.column_value && col.column_value !== "caucasian" && col.column_value !== "no_answer" && col.column_value !== "indigenous")
      .flatMap((col) => col.codes);
    this.addToSet(codes, minorityCodes);

    return this.calculatePercentage(codes.size);
  }

  get disabilityDistribution(): string {
    const codes = new Set<string>();

    const disabilityCodes = (this.reportData.eirTotalData.disability.totals.cross_analysis[0].columns as any[])
      .filter((col) => col.column_value && col.column_value !== "no" && col.column_value !== "no_answer")
      .flatMap((col) => col.codes);
    this.addToSet(codes, disabilityCodes);

    return this.calculatePercentage(codes.size);
  }

  get designatedGroupDistribution(): string {
    const codes = new Set<string>();

    const femaleColumns = (this.reportData.eirTotalData.gender.totals.cross_analysis[0].columns as any[]).find((col) => col.column_value === "female");
    this.addToSet(codes, femaleColumns ? femaleColumns.codes : undefined);

    const indgColumns = (this.reportData.eirTotalData.ethnicity.totals.cross_analysis[0].columns as any[]).find((col) => col.column_value === "indigenous");
    this.addToSet(codes, indgColumns ? indgColumns.codes : undefined);

    const minorityCodes = (this.reportData.eirTotalData.ethnicity.totals.cross_analysis[0].columns as any[])
      .filter((col) => col.column_value !== "no_answer" && col.column_value !== "caucasian")
      .flatMap((col) => col.codes);
    this.addToSet(codes, minorityCodes);

    const disabilityCodes = (this.reportData.eirTotalData.disability.totals.cross_analysis[0].columns as any[])
      .filter((col) => col.column_value && col.column_value !== "no" && col.column_value !== "no_answer")
      .flatMap((col) => col.codes);
    this.addToSet(codes, disabilityCodes);

    return this.calculatePercentage(codes.size);
  }

  get lgbtqDistribution(): string {
    const codes = (this.reportData.eirTotalData.orientation.totals.cross_analysis[0].columns as any[])
      .filter((col) => col.column_value && col.column_value !== "none" && col.column_value !== "heterosexual")
      .flatMap((col) => col.codes);
    if (codes) {
      return this.calculatePercentage(codes.length);
    }
    return "0";
  }

  get designatedGroupPlusDistribution(): string {
    const codes = new Set<string>();

    const femaleColumns = (this.reportData.eirTotalData.gender.totals.cross_analysis[0].columns as any[]).find((col) => col.column_value === "female");
    this.addToSet(codes, femaleColumns ? femaleColumns.codes : undefined);

    const indgColumns = (this.reportData.eirTotalData.ethnicity.totals.cross_analysis[0].columns as any[]).find((col) => col.column_value === "indigenous");
    this.addToSet(codes, indgColumns ? indgColumns.codes : undefined);

    const minorityCodes = (this.reportData.eirTotalData.ethnicity.totals.cross_analysis[0].columns as any[])
      .filter((col) => col.column_value && col.column_value !== "no_answer" && col.column_value !== "caucasian")
      .flatMap((col) => col.codes);
    this.addToSet(codes, minorityCodes);

    const disabilityCodes = (this.reportData.eirTotalData.disability.totals.cross_analysis[0].columns as any[])
      .filter((col) => col.column_value && col.column_value !== "no" && col.column_value !== "no_answer")
      .flatMap((col) => col.codes);
    this.addToSet(codes, disabilityCodes);

    const lgbtqCodes = (this.reportData.eirTotalData.orientation.totals.cross_analysis[0].columns as any[])
      .filter((col) => col.column_value && col.column_value !== "none" && col.column_value !== "heterosexual")
      .flatMap((col) => col.codes);
    this.addToSet(codes, lgbtqCodes);

    return this.calculatePercentage(codes.size);
  }

  private addToSet(set: Set<string>, codes: string[] | undefined) {
    if (codes) {
      codes.forEach((code) => {
        set.add(code);
      });
    }
  }

  private calculatePercentage(numerator: number) {
    return ((numerator / this.recordCount) * 100).toFixed(1);
  }
}
